// Underline all links.
.underline_links{
    *{
        a{ text-decoration: underline; }
    }
}

// Sidebar components
.sidebar_avatar_background{
    background-color: $elements__background;
}

.sidebar_avatar_border{
    border: 0.12rem solid $color__clickable;
}

.sidebar_avatar_circle{
    border-radius: 50%;
}

// Inputs components

.inputs_style{
    font-size: 1.8rem;
    background-color: $elements__background;
    width: 100%;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid $color__text;
    outline: none;
    border-radius: 0px;
    height: auto;
    &:hover,&:focus,&:active,&:visited{
      background-color: $elements__background;
      box-shadow: none;
      outline: none;
    }
    &:focus, &:active, &:visited{
      border-bottom: 1px solid $color__clickable;
    }
}

// TextArea style

.textarea_style{
    background-color: $elements__background;
    font-size: 1.17rem;
    width: 100%;
    box-shadow: none;
    border: none;
    border-left: 1px solid $color__text;
    outline: none;
    border-radius: 0px;
    height: auto;
    &:hover,&:focus,&:active,&:visited{
        background-color: $elements__background;
        box-shadow: none;
        outline: none;
      }
    &:focus, &:active, &:visited{
        border-left: 1px solid $color__clickable;
    }
}

.elements_box{
    background-color: $elements__background;
    border-radius: 20px;
    border: 1px solid $elements__box_border_color;
}

.element_opacity{
    opacity: 1;
    transition: 0.5s;
    &:hover{
        opacity: 0.75;
        color: $color__text;
    }
}

.buttons_style{
    padding: 8px;
    background-color: $color__clickable;
    border-radius: 5px;
    width: auto;
    text-decoration: none !important;
    color: $buttons__color;
    opacity: 1;
    min-width: 100px;
    transition: 0.71s;
    &:hover{
        background-color: $color__clickable;
        color: $buttons__color;
        opacity: 0.84;
    }
    &:hover,&:focus,&:visited,&:active{
        text-decoration: none;
    }
}

// Text and Font for all texts.
.content_text_font_size{
    line-height: 1.8rem;
    font-size: 1.12rem;
    // p{
        // font-size: 1.12rem;
    // }
}

// Scrollbar modern
.scrollbar_modern{
    $boder: 10px;
    $size: 7px;
    /* Custom scrollbar */
    ::-webkit-scrollbar {
      width: $size;
      height: $size;
      margin-right: 9px;
      background: $background__full;
    }
  
    /* Track - Bg */
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px transparent;
      -webkit-border-radius: $boder;
      border-radius: $boder;
    }
  
    /* Handle */
    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: $boder;
      border-radius: $boder;
      background: $color__clickable;
      -webkit-box-shadow: inset 0 0 6px $color__clickable;
    }
    ::-webkit-scrollbar-thumb:window-inactive {
    background: $color__clickable;
    }
  }

.animation {
    animation-name: fade;
    animation-duration: 500ms;
    &__move{
        animation-name: move;
        animation-duration: 500ms;
        animation-delay: 250ms;
        animation-fill-mode: backwards;
    }
    &__down{
        animation-name: down;
        animation-duration: 500ms;
    }
    &__pulse{
        animation: pulse 1s linear;
    }
    &__sidebar-title{
        animation: move 500ms;
        animation-delay: 250ms;
        animation-fill-mode: backwards;
    } 
    &__sidebar-menu{
        animation: move 600ms;
        animation-delay: 290ms;
        animation-fill-mode: backwards;
    }
    &__content{
        animation: move_invert 670ms;
        animation-delay: 310ms;
        animation-fill-mode: backwards;
    }
    &__terminal{
        animation: fade 670ms;
        animation-delay: 310ms;
        animation-fill-mode: backwards;
    }
}
