/* Theme Whiteglass
––––––––––––––––––––––––––––––––––––––––––––––––––  */
// Default

// Default
$background__full: #fff !default;
$elements__background: transparent !default;
$color__clickable: #666666 !default;
$color__text: #000 !default;
$texts__background: rgba(226, 217, 218, 0.42) !default;
$hr__color: #000 !default;
$elements__box_border_color: transparent;
$terminal__shadow_color: #000;
$buttons__color: #fff !default;

// Keyframes
$keyframe__pulse_color_primary: rgba(26, 25, 25, 0.158);
$keyframe__pulse_color_secondary: rgba(48, 47, 47, 0.103);

// Highlighter
$highlight__background: #fafafa;
$highlight__border_color: #fafafa;
$highlight__color: #000;

// Footer
$footer__bg: #fff !default;

// Blockquote
$blockquote__background: #fafafa;

// Table
$table__thead_th_background: #fff;
$table__thead_th_color: #000;
$table__thead_th_border: #1b1b1b;
$table__tbody_td_background: #fff;
$table__tbody_td_color: #000;
$table__tbody_td_border: #1b1b1b;

// Gist theme
$gist__background: #fff;
$gist__code_color: black;
$gist__border: #eeeeee #eeeeee #eeeeee;
$gist__ln_background: #fff;
$gist__ln_color: black;
$gist__meta_color: black;
$gist__meta_link_color: #666666;
$gist__comment_color: #386931;
$gist__function_color: #246194;
$gist__fn_color: #ad8500;
$gist__string_color: #c55829;
$gist__var_color: #132b44;
