/* 
––––––––––––––––––––––––––––––––––––––––––––––––––  */

.intro{
    &__terminal{
        font-family: $font-family__terminal;
        // @media (max-width: 768px) {
        // display: none;
        // }
        margin-top: 2%;
        -webkit-box-shadow: 0px 0px 56px -3px $terminal__shadow_color;
        -moz-box-shadow: 0px 0px 56px -3px $terminal__shadow_color;
        box-shadow: 0px 0px 56px -3px $terminal__shadow_color;
        border-radius: 7px 7px 5px 5px;

        /* .intro__terminal .header */
        .header{
        background: #000;
        border-radius: 7px 7px 0 0;
        padding-left: 10px;
        color: #fff;

        /* .header__btn */
        &__btn{
            float: left;
            padding: 0;
            width: 12px;
            height: 12px;
            margin: 8px 5px 0 0;
            display: inline-block;
            border-radius: 50%;

            /* .header__btn--green */
            &--green{
            background-color: #3BB662;
            } /* END - .header__btn--green */

            /* .header__btn--yellow */
            &--yellow{
            background-color: #ffe609;
            } /* END - .header__btn--yellow */

            /* .header__btn--red */
            &--red{
            background-color: #ec4d3b;
            } /* END - .header__btn--red */

        } /* END - .header__btn */

        } /* END - .intro__terminal .header */


        /* .intro__terminal .title */
        .title{
        font-size: 9pt !important;
        text-align: center;
        padding: 3px 0px;

        /* .intro__terminal .title__text */
        &__text{
            margin-left: -56px;
            color: #fff;
        } /* END -.intro__terminal .title__text */

        } /* END - .intro__terminal .titl */


        /* .intro__terminal .body */
        .body{
        color: #3bff1a;
        background: #000;
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
        overflow: auto;
        font-weight: 400;
        min-height: 250px;
        border-radius: 0 0 5px 5px;
        line-height: 1.1rem;
        font-size: 10pt !important;
        p{
            a{
                color: #3BB662;
            }
        }

        /* .intro__terminal .body__output */
        &__output{
            min-height: 21px !important;
            span{
                display: inline;
                min-height: 21px !important;
            }
            &:after {
            content:" _";
            opacity: 0;
            color: white;
            animation: cursor 1s infinite;
            min-height: 21px !important;
            }
            &__user{
                color: magenta;
            }
            &__at{
                color: white;
            }
            &__os{
                color: yellow;
            }
            &__dir{
                color: cyan;
            }
        } /* END - .intro__terminal .body__output */

        } /* END - .intro__terminal .body */
    } /* END - .intro__terminal */
}