/* Fonts
––––––––––––––––––––––––––––––––––––––––––––––––––  */

// Loading font active.
$font-family__load1: 'Special Elite', cursive;
$font-family__load2: 'Open Sans', sans-serif; 
$font-family__load3: 'Inconsolata', monospace;

// Loading font inactive.
$font-family__load4: 'Roboto', sans-serif;
$font-family__load5: 'Gloria Hallelujah', cursive;
$font-family__load6: -apple-system,BlinkMacSystemFont,segoe ui,Roboto,Oxygen,Ubuntu,Cantarell,open sans,helvetica neue,sans-serif;

// Set
$font-family__primary: $font-family__load1;
$font-family__secondary: $font-family__load1;
$font-family__third: $font-family__load2;
$font-family__terminal: $font-family__load3;

