/* Stylesheets for print page Resume
---------------------------------------------------------------*/

@media print {
  .sidebar,.footer, .scrolltop, #markdown-toc, .remove-printer{
    display: none !important;
  }
  .scrolltop__button{
    display: none !important;
  }
  .layout_default__content{
    border: none !important;
  }
  .layout_resume{
    border: none !important;
    line-height: 1.5rem;
    &__header, &__excerpt{
      display: none;
    }
    &__photo_cv{
      display: block !important;
    }
    &__content{
      color: #000;
      border: none !important;
      a,code{
        color: #000;
      }
      .emoji{
      	display: none !important;
      }
      hr{
        border-top: 1px solid #000;
      }
      /* Avoid printing hrefs when this class is applied */
      a[href]:after {
        content: none !important;
      }
      .badge__plugin{
        box-shadow: none !important;
        border: 1px solid #000 !important;
        color: #000 !important;
        background-color: white !important;
        font-weight: bold;
      }
      blockquote{
        border: none;
        background-color: transparent;
      }
    }
  }
}
