.sidebar{
    background-color: $background__full;
    &__avatar_flip{
        // position: relative;
        perspective: 1000;
        margin: 10px auto;
        width: 165px;
        height: 165px;
        &-card{
            transform-style: preserve-3d;
            transition: all 1.0s linear;
        }        
        &-face{
            position: absolute;
            backface-visibility: hidden;
        }
    }
    .sidebar__avatar_flip-back.sidebar__avatar_flip-face{
        display: block;
        transform: rotateY(180deg);   
    }
    &__avatar_flip, &__avatar_noflip{
        .image_avatar{
            width: 165px;
            height: 165px;
        }
    }
    &__title{
        font-family: $font-family__secondary;
        background-color: $elements__background;
        border-radius: 20px;
        color: $color__clickable;
        border: 1px solid $elements__box_border_color;
    }
    &__menu{
        font-family: $font-family__secondary;
        background-color: $elements__background;
        border-radius: 20px;
        border: 1px solid $elements__box_border_color;
        &-title{
          font-size: 1.3rem;
        }
        &-link{
          font-size: 1.3rem;
          line-height: 1.64rem;
          &:hover{
            text-decoration: underline;
          }
        }
    }
}
