.layout_tag{
    &__header, &__content{
    	@extend .content_text_font_size;
        @extend .elements_box;
    }
    &__time{
        background-color: $texts__background;
        opacity: 0.77;
        color: $color__text;
        border-radius: 5px;
    }
    &__comments{
        &-count{}
    }
}