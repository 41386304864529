.sidebar{
    &__avatar_flip{
        @media (min-width: 575px) and (max-width: 992px){ 
            width: 20%;
            height: auto;
        }
        @media (min-width: 768px){
            &:hover .sidebar__avatar_flip-card {
                transform: rotateY(180deg);
            }
        }
    }
    &__avatar_noflip{
        @media (min-width: 575px) and (max-width: 992px){ 
            width: 20%;
            height: auto;
        }
        // img{
        //     @media (max-width: 992px){
        //         width: 90px;
        //     }
        // }
    }
    &__title{
        @media (max-width: 992px){
            text-align: left !important;
        }
    }
    &__menu{
        // @media (min-width: 768px) and (max-width: 992px) {
        //   width: 190px;
        //   margin: 0 auto;
        // }
    }
}
