.layout_resume{
    @extend .content_text_font_size;

    &__btnprint{
      @extend .buttons_style;
    }
    &__header{
      @extend .elements_box;
    }
    &__photo_cv{
      margin-top: -15px;
    }
    &__content{
      font-family: $font-family__third;
      @extend .elements_box;
    }
  }