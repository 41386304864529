/* Stylesheets for global elements
––––––––––––––––––––––––––––––––––––––––––––––––––  */

html,body{
  color: $color__text;
  hr{
    border-top: 1px solid $hr__color;
  }
  font: 100% verdana, arial, tahoma, sans-serif;
  *{
    a{
      color: $color__clickable;
      font-weight: bold;
      &:hover{
        color: $color__clickable;
        text-decoration: none;
      }
      @extend .element_opacity;
    }
    a:focus {outline:0 !important;}
    button:focus {outline:0 !important;}
    input:focus {outline:0 !important;}
  }
  @extend .scrollbar_modern;
}

body{
  font-family: $font-family__primary;
  background-color: $background__full;
  overflow-x: hidden;
}
