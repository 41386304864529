/*
––––––––––––––––––––––––––––––––––––––––––––––––––  */

@import "sidebar";
@import "content";
@import "footer";
@import "terminal";
@import "socials_networks";
@import "email_successfully_sent";
@import "email_failed_sent";
@import "affiliates";

// VVFolded
@import "vvfolded";